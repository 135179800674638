import 'nprogress/nprogress.css';
import NProgress from 'nprogress';
import { useEffect } from 'react';

const ProgressIndicator = () => {
    useEffect(() => {
        NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false });
        NProgress.start();

        return () => {
            NProgress.done();
        };
    }, []);

    return null;
};

export default ProgressIndicator;
