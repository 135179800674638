import React, { Suspense } from 'react';
import ProgressIndicator from 'components/ProgressIndicator';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (Component) => (props) =>
(
    <Suspense fallback={<ProgressIndicator />}>
        <Component {...props} />
    </Suspense>
);

export default Loadable